.App-header {
    background-color:#090909;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.App-main {
    min-height: 94vh; 
  }

.App-footer {
    min-height: 6vh; 
  }  

.particle{
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

@media screen and (max-width: 1200px) {
  .App-left {
    padding-top: 10%;
  }
}
