
.Contact-text{
  text-align: center;
 }

.p-heading1{
  font-size: 4rem;
  font-family: 'Google', sans-serif;
  font-weight: 600;
}

.p-heading2{
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Google', sans-serif;
}

.contact-left-footer{
  width: 100%;
  font-size: 1.7rem;
}

@media screen and (max-width: 700px) {
  .p-heading1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 399px) {
  .p-heading1 {
    font-size: 2.5rem;
  }
}