.Contact-header {
  background-color:#090909;
  color: white;
}

.Hamburger-menu{
  min-height: 7vh; 
}

.Contact-main {
  min-height: 87vh; 
 }

.Contact-left{
  margin: auto;    
  display: block;
}

.Contact-right{
  margin: auto;    
  display: block;
}

.Contact-footer {
  min-height: 6vh; 
}  

@media screen and (max-width: 1200px) {
  .Contact-left {
    padding-top: 10%;
  }
}