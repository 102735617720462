  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      height: 55vh;
      margin: auto;    
      display: block;
      border-radius: 30px;
      margin-top: 15vh;
  }

  @media screen and (max-width: 450px) {
    .home-right-main-img {
      width: 80%;
      height: auto;
      margin-bottom: 5vh;
    }
  }