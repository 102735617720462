.home-left{
    height: 100%;
 }

.home-left-main{
    height: 100%;
    text-align: center;
}

.home-left-main-col{
    margin: auto;    
    display: block;
}

.first-line{
    font-size: 4.5rem;
    font-family: 'Google', sans-serif;
}

.second_line{
    font-size: 3rem;
    font-family: 'Google', sans-serif;
}

.typical{
    font-size: 1.6rem;
    font-family: 'Google', sans-serif;
}

.home-left-aboutme-main{
    position: relative;
    z-index: -1;
}

.home-left-aboutme{
   color: aliceblue;
}

.home-left-aboutme:hover{
    color: black;
 }

.home-left-aboutmme-btn:hover{
     background: #666666;
 }

 .home-left-button-text{
    font-family: 'Google', sans-serif;
    text-decoration: none;
    color: 'white';
 }

