@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,700,800);
body, html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
    background-color:#090909;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.App-main {
    min-height: 94vh; 
  }

.App-footer {
    min-height: 6vh; 
  }  

.particle{
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

@media screen and (max-width: 1200px) {
  .App-left {
    padding-top: 10%;
  }
}

.home-left{
    height: 100%;
 }

.home-left-main{
    height: 100%;
    text-align: center;
}

.home-left-main-col{
    margin: auto;    
    display: block;
}

.first-line{
    font-size: 4.5rem;
    font-family: 'Google', sans-serif;
}

.second_line{
    font-size: 3rem;
    font-family: 'Google', sans-serif;
}

.typical{
    font-size: 1.6rem;
    font-family: 'Google', sans-serif;
}

.home-left-aboutme-main{
    position: relative;
    z-index: -1;
}

.home-left-aboutme{
   color: aliceblue;
}

.home-left-aboutme:hover{
    color: black;
 }

.home-left-aboutmme-btn:hover{
     background: #666666;
 }

 .home-left-button-text{
    font-family: 'Google', sans-serif;
    text-decoration: none;
    color: 'white';
 }


  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      height: 55vh;
      margin: auto;    
      display: block;
      border-radius: 30px;
      margin-top: 15vh;
  }

  @media screen and (max-width: 450px) {
    .home-right-main-img {
      width: 80%;
      height: auto;
      margin-bottom: 5vh;
    }
  }
.social-icons{
    text-align: center;
  }
 
  .fb{
    margin: 2%;
    color: white;
  }

  .fb:hover{
    color: #386ddd;
  }

  .git{
    margin: 2%;
    color: white;
  }

  .git:hover{
    color: #e91e63;
  }

  .twitter{
    margin: 2%;
    color: white;
  }

  .twitter:hover{
    color: #00acee;
  }

  .insta{
    margin: 2%;
    color: white;
  }

  .insta:hover{
    color: #c1558b;
  }

  .linkedin{
    margin: 2%;
    color: white;
  }
  
  .linkedin:hover{
    color: #0e76a8;
  }
@font-face {
  font-family: 'Google', sans-serif;
  src: url(http://cdn.rkv.one/rkv/assets/fonts/GoogleSans/GoogleSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Google', sans-serif;
  src: url(http://cdn.rkv.one/rkv/assets/fonts/GoogleSans/GoogleSans-Bold.ttf);
  font-weight: 700;
}

.footer{
    height: 100%;
    text-align: center;
    font-size: medium;
    color: rgb(202, 200, 200);
    font-family: 'Google', sans-serif;
    font-weight: 700;
}

.Contact-header {
  background-color:#090909;
  color: white;
}

.Hamburger-menu{
  min-height: 7vh; 
}

.Contact-main {
  min-height: 87vh; 
 }

.Contact-left{
  margin: auto;    
  display: block;
}

.Contact-right{
  margin: auto;    
  display: block;
}

.Contact-footer {
  min-height: 6vh; 
}  

@media screen and (max-width: 1200px) {
  .Contact-left {
    padding-top: 10%;
  }
}
:root {
  --overlay-color: rgba(31 , 31, 31 , 0.85);
  }

.menu-wrap {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1001;
   font-family: 'Google', sans-serif;
 }
 
 .menu-wrap .toggler {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1002;
   cursor: pointer;
   width: 50px;
   height: 50px;
   opacity: 0;
 }
 
 .menu-wrap .hamburger {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1001;
   width: 80px;
   height: 80px;
   padding: 1rem;
   background: var(--primary-color);
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 /* Hamburger Line */
 .menu-wrap .hamburger > div {
   position: relative;
   flex: none;
   width: 50%;
   height: 2px;
   background: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: all 0.4s ease;
 }
 
 /* Hamburger Lines - Top & Bottom */
 .menu-wrap .hamburger > div::before,
 .menu-wrap .hamburger > div::after {
   content: '';
   position: absolute;
   z-index: 1001;
   top: -10px;
   width: 100%;
   height: 2px;
   background: inherit;
 }
 
 /* Moves Line Down */
 .menu-wrap .hamburger > div::after {
   top: 10px;
 }
 
 /* Toggler Animation */
 .menu-wrap .toggler:checked + .hamburger > div {
   -webkit-transform: rotate(135deg);
           transform: rotate(135deg);
 }
 
 /* Turns Lines Into X */
 .menu-wrap .toggler:checked + .hamburger > div:before,
 .menu-wrap .toggler:checked + .hamburger > div:after {
   top: 0;
   -webkit-transform: rotate(90deg);
           transform: rotate(90deg);
 }
 
 /* Rotate On Hover When Checked */
 .menu-wrap .toggler:checked:hover + .hamburger > div {
   -webkit-transform: rotate(225deg);
           transform: rotate(225deg);
 }
 
 /* Show Menu */
 .menu-wrap .toggler:checked ~ .menu {
   visibility: visible;
 }
 
 .menu-wrap .toggler:checked ~ .menu > div {
   -webkit-transform: scale(1);
           transform: scale(1);
   transition-duration: var(--menu-speed);
 }
 
 .menu-wrap .toggler:checked ~ .menu > div > div {
   opacity: 1;
   transition:  opacity 0.01s ease 0.01s;
 }
 
 .menu-wrap .menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .menu-wrap .menu > div {
   background: rgba(31 , 31, 31 , 0.85);
   background: var(--overlay-color);
   width: 200vw;
   height: 200vw;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .menu-wrap .menu > div > div {
   text-align: center;
   max-width: 100vw;
   max-height: 100vh;
   opacity: 0;
 }
 
 ul {
  position: relative;
}

ul li{
  list-style: none;
  text-align: center;
}

ul li a{
  color: #fff;
  text-decoration: none;
  font-size: 1.7em;
  padding: 5px 20px;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

ul:hover li a{
  color:  rgba(255,255,255,1);
}

ul li:hover a{
  font-size: 3em;
  color: #000;
  background: rgba(255,255,255,1);
}

ul li a:before{
  content: '';
  position: absolute;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 500px;
}

.hamburger-items a:hover{
  text-decoration: none;
}


.Contact-text{
  text-align: center;
 }

.p-heading1{
  font-size: 4rem;
  font-family: 'Google', sans-serif;
  font-weight: 600;
}

.p-heading2{
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Google', sans-serif;
}

.contact-left-footer{
  width: 100%;
  font-size: 1.7rem;
}

@media screen and (max-width: 700px) {
  .p-heading1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 399px) {
  .p-heading1 {
    font-size: 2.5rem;
  }
}
.Loading-header {
    background-color: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

@media screen and (max-width: 998px) {
  #loaderBox {
    width: 25%;
  }
}

@media screen and (max-width: 798px) {
  #loaderBox {
    width: 30%;
  }
}

@media screen and (max-width: 548px) {
  #loaderBox {
    width: 35%;
  }
}
/** Styles for the 403 Page **/

.particle-error,
.permission_denied,
#tsparticles {
    width: 100%;
    height: 100vh;
    margin: 0px !important;
}

#tsparticles {
    position: fixed !important;
    opacity: 0.23;
}

.permission_denied {
    background: #24344c !important;
}

.permission_denied a {
    text-decoration: none;
}

.denied__wrapper {
    max-width: 390px;
    width: 100%;
    height: 390px;
    display: block;
    margin: 0 auto;
    position: relative;
    padding-top: 7vh;
}

.permission_denied h1 {
    text-align: center;
    color: #fff;
    font-family: "Dosis", sans-serif;
    font-size: 100px;
    margin-bottom: 0px;
    font-weight: 800;
}

.permission_denied h3 {
    text-align: center;
    color: #fff;
    font-size: 19px;
    line-height: 23px;
    max-width: 330px;
    margin: 0px auto 30px auto;
    font-family: "Dosis", sans-serif;
    font-weight: 400;
}

.permission_denied h3 span {
    position: relative;
    width: 65px;
    display: inline-block;
}

.permission_denied h3 span:after {
    content: "";
    border-bottom: 3px solid #ffbb39;
    position: absolute;
    left: 0;
    top: 43%;
    width: 100%;
}

.denied__link {
    background: none;
    color: #fff;
    padding: 12px 0px 10px 0px;
    border: 1px solid #fff;
    outline: none;
    border-radius: 7px;
    width: 150px;
    font-size: 15px;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    display: block;
    margin-bottom: 40px;
    margin-top: 10px;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

.denied__link:hover {
    color: #ffbb39;
    border-color: #ffbb39;
    cursor: pointer;
    opacity: 1;
}

.permission_denied .stars {
    -webkit-animation: sparkle 1.6s infinite ease-in-out alternate;
            animation: sparkle 1.6s infinite ease-in-out alternate;
}

@-webkit-keyframes sparkle {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes sparkle {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

#astronaut {
    width: 43px;
    position: absolute;
    right: 20px;
    top: 210px;
    -webkit-animation: spin 4.5s infinite linear;
            animation: spin 4.5s infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@media (max-width: 600px) {
    .permission_denied h1 {
        font-size: 75px;
    }
    .permission_denied h3 {
        font-size: 16px;
        width: 200px;
        margin: 0 auto;
        line-height: 23px;
    }
    .permission_denied h3 span {
        width: 60px;
    }
    #astronaut {
        width: 35px;
        right: 40px;
        top: 170px;
    }
}

.saturn,
.saturn-2,
.hover {
    -webkit-animation: hover 2s infinite ease-in-out alternate;
            animation: hover 2s infinite ease-in-out alternate;
}

@-webkit-keyframes hover {
    0% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px);
    }
    100% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px);
    }
}

@keyframes hover {
    0% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px);
    }
    100% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px);
    }
}

