@font-face {
  font-family: 'Google', sans-serif;
  src: url(http://cdn.rkv.one/rkv/assets/fonts/GoogleSans/GoogleSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Google', sans-serif;
  src: url(http://cdn.rkv.one/rkv/assets/fonts/GoogleSans/GoogleSans-Bold.ttf);
  font-weight: 700;
}

.footer{
    height: 100%;
    text-align: center;
    font-size: medium;
    color: rgb(202, 200, 200);
    font-family: 'Google', sans-serif;
    font-weight: 700;
}
